import './app.scss';

window.addEventListener('DOMContentLoaded', function () {
    const domain = `${window.location.origin}/${document.documentElement.lang}/jobs`;
    console.info(
        `%c  ___ _____      _____      _ _      _ _        _  
 | _ \\ __\\ \\    / / __|  __| (_)__ _(_) |_ __ _| | 
 |   / _| \\ \\/\\/ /| _|  / _\` | / _\` | |  _/ _\` | | 
 |_|_\\___| \\_/\\_/ |___| \\__,_|_\\__, |_|\\__\\__,_|_| 
                               |___/               
                                                   `,
        'background: #00195a; color: #d2eb23; font-size:24px;',
        `\n\nconsole.log('Find your new home at REWE digital!');`,
        `\n\nconsole.log('Apply now at ${domain}');`
    );

    const contentUpdateEvent = new CustomEvent('content.loaded', {
        view: window,
        bubbles: true,
        cancelable: false,
        detail: {
            $context: document
        }
    });

    window.dispatchEvent(contentUpdateEvent);

    // Remove no Transitions for all Elements at Start
    setTimeout(() => {
        document.body.classList.remove('has--no-animation');
    }, 1000);

    ObserveVideos();

    PreloadRive();

    EnableImageZoom();
});

function EnableImageZoom() {
    const zoomableImages = document.querySelectorAll('[data-zoom="true"]');
    zoomableImages.forEach((image) => {
        // eslint-disable-next-line no-undef
        const lightbox = new FsLightbox();
        const imageElement = image.querySelector('picture img');
        if (!imageElement) {
            return;
        }

        const addZoomButton = (container) => {
            if (!container) {
                return;
            }

            const zoomButton = document.createElement('div');
            zoomButton.classList.add('zoom-button');
            zoomButton.classList.add('black');

            const zoomIcon = document.createElement('img');
            zoomIcon.src = '/dist/assets/images/lupe.svg';

            zoomButton.appendChild(zoomIcon);

            container.style.position = 'relative';
            container.appendChild(zoomButton);
        };

        const setupImage = (lightbox, image) => {
            lightbox.props.sources = [
                imageElement.dataset.original || imageElement.src
            ];
            lightbox.props.onOpen = (instance) => {
                instance.core.clickZoomer.zoomIn = () => {};
                instance.core.clickZoomer.zoomOut = () => {};
            };

            lightbox.props.captions = [imageElement.title || imageElement.alt];
            image.addEventListener('click', () => {
                lightbox.open();
            });
        };

        const container = imageElement?.parentElement?.parentElement;
        addZoomButton(container);

        setupImage(lightbox, image);
    });
}

function PreloadRive() {
    const preloadLink = document.createElement('link');
    preloadLink.rel = 'preload';
    preloadLink.as = 'fetch';
    preloadLink.href =
        'https://unpkg.com/browse/@rive-app/canvas@2.19.3/rive.wasm';
    preloadLink.crossOrigin = 'anonymous';
    document.head.appendChild(preloadLink);
}

function ObserveVideos() {
    // Skip if in pimcore editmode
    if (document.body.classList.contains('pimcore_version_11')) {
        return;
    }

    // Handle Video Play/Pause based on viewport visibility
    const observedVideos = new Map();
    setInterval(() => {
        // console.log('Checking for Videos', observedVideos.size);

        const videoElements = document.querySelectorAll(
            '.pimcore_editable_video iframe'
        );
        videoElements.forEach((videoElement) => {
            if (observedVideos.has(videoElement)) {
                return;
            }

            videoElement.contentWindow.postMessage(
                '{"event":"command","func":"stopVideo","args":""}',
                '*'
            );

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // console.log('Video is in Viewport');
                        videoElement.contentWindow.postMessage(
                            '{"event":"command","func":"playVideo","args":""}',
                            '*'
                        );
                    } else {
                        // console.log('Video is not in Viewport');
                        videoElement.contentWindow.postMessage(
                            '{"event":"command","func":"pauseVideo","args":""}',
                            '*'
                        );
                    }
                });
            });
            observer.observe(videoElement);
            observedVideos.set(videoElement, observer);
        });
    }, 1000);
}
